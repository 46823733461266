.layout-main-container-dash {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 7rem 2rem 2rem 4rem;
    transition: margin-left $transitionDuration;
}

.custom-colors{
    // background-color: var(--surface-card);
    background-color: var(--surface-overlay);
}

.layout-main-container {
    display: flex;
    flex-direction: column;
    position:fixed;
    height: calc(100vh - 8.5rem);
    justify-content: space-between;
    overflow-y: auto;
    top: 7rem;
    // left: 3rem;
    right: 2rem;
    // transition: margin-left $transitionDuration;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    // padding: 0rem 2rem 2rem 2rem;
    padding: 0rem 1rem 1rem 1rem;
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);   
    z-index: 99;
}

.topbar-menu {
    position: sticky;
    width: 100%;
    top: 0px;
    background-color: red;
    z-index: 1100;
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);  
}

.topbar-menu.topbar-menu-sticky {
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
}

.layout-datatable {
    display: flex;
    flex-direction: column;
    position:fixed;
    height: calc(80vh - 9rem);
    // justify-content: space-between;
    // overflow-y: auto;
    // top: 7rem;
    // left: 3rem;
    // right: 2rem;
    // transition: margin-left $transitionDuration;
    // background-color: var(--surface-overlay);
    // border-radius: 12px;
    // padding: 0rem 2rem 2rem 2rem;
    // box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);   
    // z-index: 1000;
}

// .layout-main {
//     flex: 1 1 auto;
// }


// .layout-footer{
//     position:absolute;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     height:2.5rem;
// }

//Dropdown
.p-dropdown-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.p-dropdown-wrapper {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.p-dropdown-wrapper::-webkit-scrollbar-track {
    background: var(--trackBG) ;
}

.p-dropdown-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 1px solid var(--thumbBG);
}

.p-dropdown-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #616161;
}

.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: var(--trackBG);
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 1px solid var(--thumbBG);
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #616161;
  }




// .container-detail-x {
//     display: flex;
//     flex-wrap: nowrap;
// }

// .container-detail-x .card {
//     flex: 0 0 auto;
// }

// .floating-buttons {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     right: 0;
// }

// .scroll-btn {
//     background-color: #007bff;
//     color: #fff;
//     border: none;
//     border-radius: 50%;
//     width: 40px;
//     height: 40px;
//     font-size: 20px;
//     margin-left: 5px;
//     cursor: pointer;
// }

// .scroll-btn:hover {
//     background-color: #0056b3;
// }





///////////2
// .container-detail-x {
//     display: flex;
//     flex-wrap: nowrap;
// }

// .container-detail-x .card {
//     flex: 0 0 auto;
// }

// .container-detail-x .floating-buttons {
//     position: absolute;
//     // top: 50%;
//     // transform: translateY(-50%);
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     // width: calc(100% - 20px); 
//     padding: 0 10px;
// }

// .scroll-btn {
//     background-color: #007bff;
//     color: #fff;
//     border: none;
//     border-radius: 50%;
//     width: 40px;
//     height: 40px;
//     font-size: 20px;
//     margin-left: 5px;
//     cursor: pointer;
// }

// .scroll-btn:hover {
//     background-color: #0056b3;
// }



// /////3



// .container-detail-x {
//     position: relative;
//     display: flex;
//     overflow-x: auto; 
//     overflow-y: hidden; 
//     scrollbar-width: thin; 
//     scrollbar-color: red; 
// }
.container-detail-x {
    position: relative;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
}

.container-detail-x .card {
    flex: 0 0 auto;
}


.container-detail-x::-webkit-scrollbar {
    width: 6px;
    height: 6px; 
}

.container-detail-x::-webkit-scrollbar-track {
    background: #fafafa ;
}

.container-detail-x::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 6px; 
}

.container-detail-x::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.container-detail-y {
    position: relative;
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width:100%
}

.container-detail-y .card {
    flex: 0 0 auto;
    width: 100%
}


.container-detail-y::-webkit-scrollbar {
    width: 6px;
    height: 6px; 
}

.container-detail-y::-webkit-scrollbar-track {
    background: #fafafa ;
}

.container-detail-y::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 6px; 
}

.container-detail-y::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}


.floating-buttons {
    position: absolute;
    top: 50%; 
    transform: translateY(-50%); 
    display: flex;
}

.scroll-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin: 0 5px; 
    cursor: pointer;
}

.scroll-btn:hover {
    background-color: #0056b3;
}

.scroll-content {
    border-bottom: 5px solid red; 
    border-radius: 10px; 
}