.swiperss {
    width: 100%;
    height: 100%;
  }
  
  .swiperss-slide {
    text-align: center;
    font-size: 18px;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiperss-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .swiperss-pagination-bullet  {
    background-color: #6366f1 !important;
  }