.root-row{
    position: sticky;
    // z-index: 4;
}

.expanded-row{
    position: sticky;
    z-index: 3;
}

