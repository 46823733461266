@media (min-width: 992px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-main-container {
                margin-left: 0;
                // padding-left: 2rem;
                left: 2rem;
            }

            .layout-sidebar {
                transform: translateX(-100%);
                left: 0;
                top: 0;
                height: 100vh;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                z-index: 99999;
            }

            .layout-date {
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
            }

            &.layout-overlay-sidebar-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }

            .selectbutton-custom {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.layout-static {
            .layout-main-container {
                margin-left: 300px;
                left: 3rem;
                // background-color: red;
            }

            .layout-date {
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
            }

            .selectbutton-custom {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            &.layout-static-sidebar-inactive {
                .layout-sidebar {
                    transform: translateX(-100%);
                    left: 0;
                }

                .layout-main-container {
                    margin-left: 0;
                    padding-left: 2rem;
                    // padding-top: 2rem;
                }
            }
        }

        .layout-mask {
            display: none;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .layout-wrapper {
        // .layout-main-container {
        //     margin-left: 0;
        //     padding-left: 2rem;
        //     background-color: blue($color: #000000);
        //     // padding-top: 2rem;
        // }

        // .layout-sidebar {
        //     transform: translateX(-100%);
        //     left: 0;
        //     top: 0;
        //     height: 100vh;
        //     border-top-left-radius: 0;
        //     border-bottom-left-radius: 0;
        // }

        // .layout-date {
        //     position: absolute;
        //     top: -15px;
        //     left: 50%;
        //     transform: translateX(-50%)
        // }

        // .layout-mask {
        //     z-index: 998;
        //     background-color: var(--mask-bg);

        //     &.layout-mask-enter-from,
        //     &.layout-mask-leave-to {
        //         background-color: transparent;
        //     }
        // }

        // &.layout-mobile-sidebar-active {
        //     .layout-sidebar {
        //         transform: translateX(0);
        //     }

        //     .layout-mask {
        //         display: block;
        //     }
        // }
        &.layout-overlay {
            .layout-main-container {
                margin-left: 0;
                // padding-left: 2rem;
                left: 2rem;
            }

            .layout-sidebar {
                transform: translateX(-100%);
                left: 0;
                top: 0;
                height: 100vh;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            .layout-date {
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
            }

            .selectbutton-custom {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            &.layout-overlay-sidebar-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }

            .layout-mask {
                z-index: 998;
                background-color: var(--mask-bg);

                &.layout-mask-enter-from,
                &.layout-mask-leave-to {
                    background-color: transparent;
                }
            }

            &.layout-mobile-sidebar-active {
                .layout-sidebar {
                    transform: translateX(0);
                }

                .layout-mask {
                    display: block;
                }
            }
        }

        &.layout-static {
            .layout-main-container {
                margin-left: 300px;
                left: 3rem;
                // background-color: red;
            }

            .layout-date {
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
            }

            .selectbutton-custom {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            &.layout-static-sidebar-inactive {
                .layout-sidebar {
                    transform: translateX(-100%);
                    left: 0;
                }

                .layout-main-container {
                    margin-left: 0;
                    padding-left: 2rem;
                    // padding-top: 2rem;
                }
            }
        }

        .layout-mask {
            z-index: 998;
            background-color: var(--mask-bg);

            &.layout-mask-enter-from,
            &.layout-mask-leave-to {
                background-color: transparent;
            }
        }

        // &.layout-mobile-sidebar-active {
        //     .layout-sidebar {
        //         transform: translateX(0);
        //     }

        //     .layout-mask {
        //         display: block;
        //     }
        // }

        // .layout-mask {
        //     z-index: 998;
        //     background-color: var(--mask-bg);

        //     &.layout-mask-enter-from,
        //     &.layout-mask-leave-to {
        //         background-color: transparent;
        //     }
        // }

        // .layout-mask {
        //     display: none;
        // }
    }

    .body-overflow-hidden {
        overflow: hidden;
    }
}

@media (max-width: 767px) {
    .layout-wrapper {
        .layout-main-container {
            margin-left: 0;
            padding-left: 2rem;
            font-size: smaller;
            left: 2rem;
            // background-color: blue;
            // padding-top: 2rem;
        }

        .layout-date {
            position: absolute;
            top: -15px;
            left: 35%;
            // transform: translateX(-50%)
        }

        .selectbutton-custom {
            position: absolute;
            left: 35%;
            transform: translateX(-50%);
        }

        .layout-sidebar {
            transform: translateX(-100%);
            left: 0;
            top: 0;
            height: 100vh;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .layout-mask {
            z-index: 998;
            background-color: var(--mask-bg);

            &.layout-mask-enter-from,
            &.layout-mask-leave-to {
                background-color: transparent;
            }
        }

        &.layout-mobile-sidebar-active {
            .layout-sidebar {
                transform: translateX(0);
            }

            .layout-mask {
                display: block;
            }
        }
    }

    .body-overflow-hidden {
        overflow: hidden;
    }
}

@media (max-width: 430px) {
    .layout-date {
        position: absolute;
        top: -15px;
        left: 15% !important;
        // transform: translateX(-50%)
    }

    .selectbutton-custom {
        position: absolute;
        left: 15% !important;
        transform: translateX(0%) !important;
    }
}
