.container {
    display: flex;
    width: 100%;
    background: inherit;
}

.buttonWrapper {
    display: flex;
    align-items: flex-end;
    background: inherit;    
}

.button {
    border: none;
    text-decoration: none;
    cursor: pointer;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    color: #374e8c;
    font-size: 20px;
    font-weight: bold;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.dateListScrollable {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    margin: 2px 0 2px -30px;
    -webkit-overflow-scrolling: touch;
}

.dateListScrollable::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
}

.monthContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 2px;
    margin: 2px;
}

.monthYearLabel {
    align-self: flex-start;
    z-index: 3;
    font-size: 13px;
    font-weight: bold;
    position: sticky;
    top: 10px;
    left: 0;
    width: max-content;
    margin: 0 10px;
}

.dateDayItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0 0 0 5px;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
}

.dateDayItemMarked {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0 0 0 5px;
    width: 35px;
    height: 50px;
    flex-shrink: 0;
}

.daysContainer {
    display: flex;
    z-index: 1;
    margin-top: 10px;
}

.dayLabel {
    font-size: 10px;
    margin: 4px 0 0 0;
}

.dateLabel {
    font-size: 14px;
}

.markedLabel {
    margin-top: 10px;
}