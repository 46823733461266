.marker-label {
    margin-bottom: 55px;
    padding: 3px;
    background-color: white;
    border: 1px solid black;
}

.car-detail {
    position: absolute;
    width: 22%;
    height: 100%;
    top: 0;
    left: 0;

    background-color: white;
}

// .p-dialog .p-dialog-content{
//     padding:0 !important;
// }

.filter-component {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 10%;
    z-index: 5;
    background-color: white;
}

.filter-component-normal {
    position: absolute;
    right: 0;
    top: 1%;
    height: 99%;
    width: 10%;
    z-index: 5;
    background-color: white;
    border: 1px solid lightseagreen;
    border-radius: 5px;
}

.filter-component-data {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 17%;
    z-index: 5;
    background-color: white;
}

.filter-component-data-normal {
    position: absolute;
    right: 0;
    top: 1%;
    height: 77vh;
    width: 27%;
    z-index: 5;
    background-color: white;
    border: 1px solid lightseagreen;
    border-radius: 5px;
}

.map-button {
    position: absolute;
    z-index: 4;
}

.filter-trigger {
    left: 1rem;
    bottom: 3rem;
}

.filter-trigger-normal {
    left: 1rem;
    bottom: 7rem;
}

.filter-data {
    left: 1rem;
    bottom: 7rem;
}

.filter-data-normal {
    left: 1rem;
    bottom: 11rem;
}

.fullscreen-trigger-normal {
    right: 1rem;
    top: 2rem;
}

.fullscreen-trigger {
    right: 1%;
    top: 2%;
}

.car-center-search-normal {
    left: 50vw;
    top: 2rem;
}

.car-center-search {
    left: 50vw;
    top: 1rem;
}
