.root-row{
    position: sticky;
    // z-index: 4;
}

.expanded-row{
    position: sticky;
    z-index: 3;
}

.overflow-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; 
    -webkit-line-clamp: 3; 
    line-height: 1.5em; 
    height: calc(1.5em * 3); 
}
