$randomNumber: random(6);

body {
    background-color: #555;
}

.p-datatable .p-datatable-tbody .d-error {
    background-color: salmon !important;
}

.p-datatable.thead {
    padding: 0 !important;
}

.width-20 {
    @media only screen and (min-width: 992px) {
        width: 16.66%;
    }

    @media only screen and (min-width: 1200px) {
        width: 20%;
    }
}

.calendarWrap {
    position: relative;
    min-width: 350px;
}

.calendarElement {
    position: absolute;
    left: 35%;
    transform: translateX(-50%);
    z-index: 99999 !important;
}

* {
    // margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.btn-delivery {
    border-color: #22c55e !important;
    background: linear-gradient(90deg, #22c55e 33%, white 33%) !important;
    color: #ffffff !important;
    font-weight: bold !important;
    // text-decoration: 4px solid red !important;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #22c55e;
}

.btn-complete {
    border-color: #22c55e !important;
    background: linear-gradient(90deg, #22c55e 66%, white 33%) !important;
    color: #ffffff !important;
    font-weight: bold !important;
    // text-decoration: 4px solid red !important;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #22c55e;
}

.btn-delivery:hover {
    border-color: rgb(32, 187, 89) !important;
    background: linear-gradient(90deg, rgb(32, 187, 89) 33%, white 33%) !important;
    color: #ffffff !important;
    font-weight: bold !important;
    // text-decoration: 4px solid red !important;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #22c55e;
}

.btn-complete:hover {
    border-color: rgb(32, 187, 89) !important;
    background: linear-gradient(90deg, rgb(32, 187, 89) 66%, white 33%) !important;
    color: #ffffff !important;
    font-weight: bold !important;
    // text-decoration: 4px solid red !important;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: #22c55e;
}

.app-background-layer {
    height: 100vh;
    background-color: var(--surface-ground);
}

.background-2 {
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.background-3 {
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.background-4 {
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.background-5 {
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.scrollHeight {
    min-height: 500px;
}

.p-image img {
    min-width: 100px;
    max-width: 250px;
    height: auto;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.2rem 0.5rem;
}

/* Input Padding */
// .p-inputtext {
//     padding-top: 0.75rem ;
//     padding-bottom: 0.5rem;
//     padding-left: 1rem;
//     padding-right: 1rem;
// }

/* Scrollbar pallet */
// html {
//     --scrollbarBG: #cfd8dc;
//     --thumbBG: #9e9e9e;
//     --trackBG: #fafafa;
//     --thumbBGConfig: #BDBDBD;
// }

// BODY SCROLLBAR
body::-webkit-scrollbar {
    width: 6px;
}

body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #888;
}

body::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

// SIDEBAR SCROLLBAR
.layout-sidebar::-webkit-scrollbar {
    width: 6px;
}

.layout-sidebar::-webkit-scrollbar {
    background: #fafafa;
}

.layout-sidebar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.layout-sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

// LAYOUT CONFIG SCROLLBAR
.layout-config-content::-webkit-scrollbar {
    width: 6px;
}

.layout-config-content::-webkit-scrollbar-track {
    background: #fafafa;
}

.layout-config-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.layout-config-content::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

//
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #fafafa;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

// LAYOUT CONTAINER SCROLLBAR

.layout-main-container::-webkit-scrollbar {
    width: 6px;
}

.layout-main-container::-webkit-scrollbar-track {
    background: #fafafa;
}

.layout-main-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.layout-main-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

// DATATABLE SCROLLBAR
.p-datatable-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.p-datatable-wrapper::-webkit-scrollbar-track {
    // background: var(--trackBG) ;
    background: #fafafa;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

// CUSTOM SCROLLBAR
.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #fafafa;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #888;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// DROPDOWN ITEMS WRAPPER SCROLLBAR
.p-dropdown-items-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.p-dropdown-items-wrapper::-webkit-scrollbar-track {
    background: #fafafa;
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

// DROPDOWN WRAPPER SCROLLBAR
.p-dropdown-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.p-dropdown-wrapper::-webkit-scrollbar-track {
    background: #fafafa;
}

.p-dropdown-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.p-dropdown-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.p-chip.custom-chip {
    /* Center the content */
    align-items: center;
    display: inline-flex;
    justify-content: center;

    /* Spacing */
    padding-top: 0.2rem;
    padding-bottom: -0.15rem;
    padding-left: 0.75rem;
    padding-right: 0.5rem;

    background: var(--primary-color);
    color: var(--primary-color-text);
}

.p-chip .p-chip-remove-icon {
    border-radius: 4px;
    transition: box-shadow 0.15s;
    margin-left: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
}

.p-menuitem-text {
    padding-top: 5px;
}

.p-datatable-frozen-tbody {
    font-weight: bold;
}

.p-datatable-scrollable .p-frozen-column {
    font-weight: bold;
}

.map-container {
    width: 100%;
    height: 100vh;
}

// .p-picklist-filter .p-picklist-filter-input {
//     margin-top: 5px;
//     margin-bottom: 5px;
// }

.p-picklist-filter-icon {
    display: none;
}

// .p-picklist-list-wrapper  {
//     height: 50rem;
// }

// ul .p-picklist-list-wrapper {
//     height: 60rem;
//     border: solid;
// }

.p-picklist-filter-container {
    margin-bottom: 10px;
}

.round {
    display: block;
    margin-left: auto;
    margin-right: auto;
    justify-items: center;
    border-radius: 50%;
    overflow: hidden;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.round-image {
    transform: translate(-20%, -30%);
}

.round-list {
    display: block;
    margin-left: 0;
    margin-right: 0;
    justify-items: center;
    border-radius: 50%;
    overflow: hidden;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.round-image-list {
    transform: translate(-20%, -20%);
}

.circle {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid gray;
}

.circle .circle-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

//INPUT LOGIN
$foregroundColor: #000;
$bakgroundColor: var(--surface-ground);
$placeholderColor: rgb(138, 138, 136);
$borderThickness: 5px;

.input-new {
    background-color: transparent;
    border-color: transparent;
    height: 100%;
    width: 100% !important;
    box-sizing: border-box;

    padding-right: 5rem;

    border-radius: 0px !important;
    color: $foregroundColor !important;
    font-weight: bold;

    @media only screen and (max-width: 1280px) {
        padding-top: 1.2rem !important;
        padding-bottom: 0.15rem !important;
    }

    @media only screen and (max-width: 768px) {
        padding-top: 0rem !important;
        margin-top: 1.5rem !important;
    }
}

.input-new:-webkit-autofill {
    -webkit-text-fill-color: $foregroundColor;
    border-radius: 0px !important;
    background-color: transparent;
    transition: background-color 999999999s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px transparent;
}

.input-new:enabled:hover {
    border-color: transparent;
}

.input-new:enabled:focus {
    box-shadow: none;
    border-color: transparent;
}

.password-input {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0px !important;
    color: $foregroundColor !important;
    margin-bottom: -1em;

    @media only screen and (max-width: 1280px) {
        padding-top: 0.5rem !important;
    }
}

.password-input:-webkit-autofill {
    -webkit-text-fill-color: $foregroundColor;
    border-radius: 0px !important;
    transition: background-color 999999999s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px transparent;
}

.password-input:enabled:hover {
    border-color: transparent;
}

.password-input:enabled:focus {
    box-shadow: none;
    border-color: transparent;
}

.password-style {
    padding: 0.7rem !important;
    padding-bottom: 0.8rem !important;
    padding-top: 0.8rem !important;
    color: $foregroundColor;

    @media only screen and (max-width: 765px) {
        padding-bottom: 0.88rem !important;
    }
}

.password-style > .p-icon-field {
    width: 95% !important;
    margin-top: 0.5rem;
}

.password-style .p-input-icon {
    margin-top: 0.05rem;
}

.password-input::-webkit-input-placeholder,
.input-new::-webkit-input-placeholder {
    color: $placeholderColor;

    @media only screen and (max-width: 1280px) {
        font-size: 1.5rem;
    }

    @media only screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
}

.password-input,
.input-new {
    font-size: 1.75rem !important;

    @media only screen and (max-width: 1280px) {
        font-size: 1.75rem !important;
    }

    @media only screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
}

.signin-button:focus {
    box-shadow: none;
}

.recordtime-button {
    width: 50%;
    @media only screen and (max-width: 1120px) {
        width: 75%;
    }

    @media only screen and (max-width: 990px) {
        width: 50%;
    }
}

.recordtime-button:focus {
    box-shadow: none;
}

.maximize-button:focus {
    border-color: transparent;
    box-shadow: none !important;
}

.p-input-icon-right > svg {
    margin-top: 0rem !important;
    margin-right: 1rem;
}

.login-icon {
    font-weight: bold;
    font-size: 1.8rem;
    @media only screen and (max-width: 1463px) {
        font-size: 1.75rem;
    }

    @media only screen and (max-width: 1022px) {
        font-size: 1.5rem;
    }

    @media only screen and (max-width: 768px) {
        font-size: 1.2rem;
    }

    @media only screen and (max-width: 360px) {
        font-size: 1.1rem;
    }
}

//404 NOT FOUND PAGE
.logo-notfound {
    width: 100%;
    opacity: 23%;
    object-fit: cover;
    margin-left: -28%;

    @media only screen and (max-width: 1358px) {
        margin-left: 0;
    }
}

.logo-notfound-wrapper {
    align-self: end;

    @media only screen and (max-width: 1358px) {
        display: flex;
        align-self: center;
        justify-self: center;
    }
}

.not-found-text1 {
    font-size: 10vw;

    @media only screen and (max-width: 1024px) {
        font-size: 15vw;
    }

    @media only screen and (max-width: 573px) {
        font-size: 18vw;
    }
}

.not-found-text2 {
    font-size: 4vw;
    margin-top: -2rem;

    @media only screen and (max-width: 1024px) {
        font-size: 7vw;
    }

    @media only screen and (max-width: 573px) {
        margin-top: -1rem;
        font-size: 8vw;
    }
}

.not-found-text3 {
    font-size: 2vw;
    @media only screen and (max-width: 1024px) {
        font-size: 4vw;
    }

    @media only screen and (max-width: 573px) {
        font-size: 5vw;
    }
}

.not-found-text4 {
    font-size: 2vw;
    @media only screen and (max-width: 1024px) {
        font-size: 5vw;
    }

    @media only screen and (max-width: 573x) {
        font-size: 6vw;
    }
}

.not-found-text5 {
    font-size: 3vw;

    @media only screen and (max-width: 1024px) {
        font-size: 6vw;
    }

    @media only screen and (max-width: 573px) {
        font-size: 7vw;
    }
}

// VIRTUAL SCROLLER SCROLLBAR
.p-virtualscroller::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.p-virtualscroller::-webkit-scrollbar-track {
    background: #fafafa;
}

.p-virtualscroller::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.p-virtualscroller::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// AUTO COMPLETE SCROLLBAR
.p-autocomplete-items-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.p-autocomplete-items-wrapper::-webkit-scrollbar-track {
    background: #fafafa;
}

.p-autocomplete-items-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.p-autocomplete-items-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.p-blockui {
    z-index: 1000 !important;
}

// PICKLIST SCROLLBAR
.p-picklist-list::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.p-picklist-list::-webkit-scrollbar-track {
    background: #fafafa;
}

.p-picklist-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.p-picklist-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// =============== ORDER UJT DATAVIEW ===================
// /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 300px) {
    // #order-ujt-dataview {
    //     max-height: 43vh;
    //     min-height: 43vh;
    // }
    // #order-non-ujt-dataview {
    //     max-height: 40vh;
    //     min-height: 40vh;
    // }
    // #order-multidrop {
    //     max-height: 41vh;
    //     min-height: 41vh;
    // }
    // #schedule-dataview {
    //     max-height: 47.5vh;
    //     min-height: 47.5vh;
    // }
    // #origin-datatable {
    //     max-height: 54vh;
    //     min-height: 54vh;
    // }
    // #rental-dataview{
    //     max-height: 41vh;
    //     min-height: 41vh;
    // }
    // #invoice-origin-datatable{
    //     max-height:46.5vh;
    //     min-height:46.5vh;
    // }
}

@media only screen and (min-width: 2000px) {
    // #order-ujt-dataview {
    //     max-height: 70vh;
    //     min-height: 43vh;
    // }
    // #order-non-ujt-dataview {
    //     max-height: 70vh;
    //     min-height: 43vh;
    // }
    // #order-multidrop {
    //     max-height: 70vh;
    //     min-height: 43vh;
    // }
    // #schedule-dataview {
    //     max-height: 70vh;
    //     min-height: 43vh;
    // }
}

// === PURCHASE | OFFICE | INVOICE ORIGIN SCROLLBAR ===
#invoice-origin-datatable::-webkit-scrollbar {
    width: 6px;
}

#invoice-origin-datatable::-webkit-scrollbar-track {
    background: #f1f1f1;
}

#invoice-origin-datatable::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #888;
}

#invoice-origin-datatable::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// === SALES | ORDER | SCHEDULE SCROLL BAR ===
#rental-dataview::-webkit-scrollbar {
    width: 6px;
}

#rental-dataview::-webkit-scrollbar-track {
    background: #f1f1f1;
}

#rental-dataview::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #888;
}

#rental-dataview::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// === SALES | SCHEDULE SCROLL BAR ===
#schedule-dataview::-webkit-scrollbar {
    width: 6px;
}

#schedule-dataview::-webkit-scrollbar-track {
    background: #f1f1f1;
}

#schedule-dataview::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #888;
}

#schedule-dataview::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// === ORDER UJT SCROLL BAR ===
#order-ujt-dataview::-webkit-scrollbar {
    width: 6px;
}

#order-ujt-dataview::-webkit-scrollbar-track {
    background: #f1f1f1;
}

#order-ujt-dataview::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: #888;
}

#order-ujt-dataview::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// === ORDER NON UJT SCROLL BAR ===
#order-non-ujt-dataview::-webkit-scrollbar {
    width: 6px;
}

#order-non-ujt-dataview::-webkit-scrollbar-track {
    background: #f1f1f1;
}

#order-non-ujt-dataview::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #888;
}

#order-non-ujt-dataview::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// === ORDER MULTIDROP SCROLL BAR ===
#order-multidrop::-webkit-scrollbar {
    width: 6px;
}

#order-multidrop::-webkit-scrollbar-track {
    background: #f1f1f1;
}

#order-multidrop::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #888;
}

#order-multidrop::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// emergency fix need to solve with elegant way

.p-selectable-row {
    height: auto !important;
}

.p-datatable-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

// calendar
.p-inputnumber > .p-inputtext:focus {
    border: none;
    outline: none;
    // box-shadow: none;
}

.fabs {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    // margin: 30px;
    // --_viewport-margin: 2.5vmin;

    // position: fixed;
    // z-index: var(--layer-1);

    // inset-block: auto var(--_viewport-margin);
    // inset-inline: auto var(--_viewport-margin);
}

.p-autocomplete-dd .p-autocomplete-dropdown {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0px;
}

.p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
}

.p-buttonset .p-button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.p-buttonset .p-button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.p-calendar-w-btn-right .p-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.order-btn {
    // width: fit-content;
    // display: flex;
    // align-items: center;
    border-radius: 50px;
}

.order-icon {
    color: #ffffff;
    border-radius: 50px;
    width: 35px;
    height: 35px;
}

// draft
.draft-btn {
    background-color: #eff3f8;
}

.draft-icon {
    // background-color: blueviolet;
    background-color: #aeafaf;
}

.draft-btn:hover {
    cursor: pointer;
    background-color: #cad2db;
}

.draft-btn:active {
    background-color: #eff3f8;
}

//assign
.assign-btn {
    background-color: #eccff3;
}

.assign-icon {
    // background-color: blueviolet;
    background-color: #b424d8;
}

.assign-btn:hover {
    cursor: pointer;
    background-color: #cad2db;
}

.assign-btn:active {
    background-color: #eff3f8;
}

//cancel
.cancel-btn {
    background-color: #f3cfcf;
}

.cancel-icon {
    // background-color: blueviolet;
    background-color: #d82424;
}

.cancel-btn:hover {
    cursor: pointer;
    background-color: #cad2db;
}

.cancel-btn:active {
    background-color: #eff3f8;
}

// open
.open-btn {
    background-color: #ff77003a;
}

.open-icon {
    color: white;
    background-color: #ff7700;
}

.open-btn:hover {
    cursor: pointer;
    background-color: #ff770056;
}

.open-btn:active {
    background-color: #ff77003a;
}

// void
.void-btn {
    background-color: #fa707036;
}

.void-icon {
    background-color: #fa7070;
}

.void-btn:hover {
    cursor: pointer;
    background-color: #fa707054;
}

.void-btn:active {
    background-color: #fa707036;
}

// transfer
.transfer-btn {
    background-color: #7aa2e33b;
}

.transfer-icon {
    background-color: #7aa2e3;
}

.transfer-btn:hover {
    cursor: pointer;
    background-color: #7aa2e357;
}

.transfer-btn:active {
    background-color: #7aa2e33b;
}

// pending
.pending-btn {
    // background-color: #90d26d33;
    background: linear-gradient(90deg, #90d26d67 40%, #eff3f8 40%);
}

.pending-icon {
    background-color: #90d26d;
}

.pending-btn:hover {
    cursor: pointer;
    background-color: #90d26d54;
}

.pending-btn:active {
    background-color: #90d26d33;
}

// pending kirim
.pending-kirim-btn {
    // background-color: #90d26d33;
    background: linear-gradient(90deg, #90d26d67 50%, #eff3f8 40%);
}

.pending-kirim-icon {
    background-color: #90d26d;
}

.pending-kirim-btn:hover {
    cursor: pointer;
    background-color: #90d26d54;
}

.pending-kirim-btn:active {
    background-color: #90d26d33;
}

// pending terima
.pending-terima-btn {
    background: linear-gradient(90deg, #90d26d67 75%, #eff3f8 20%);
}

.pending-terima-icon {
    background-color: #90d26d;
}

.pending-terima-btn:hover {
    cursor: pointer;
    background-color: #90d26d54;
}

.pending-terima-btn:active {
    background-color: #90d26d33;
}

// close
.close-btn {
    background: linear-gradient(90deg, #90d26d67 100%, #eff3f8 0%);
}

.close-icon {
    background-color: #90d26d;
}

.close-btn:hover {
    cursor: pointer;
    background-color: #90d26d54;
}

.close-btn:active {
    background-color: #90d26d33;
}

// ORDER UJT BTN NEW
// BTN BASE
.btn-order-ujt {
    height: 30px;
    border-radius: 50px;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
}
.btn-order-ujt:hover .icon-base {
    cursor: pointer;
}

.btn-order-ujt:hover .icon-base {
    transform: scale(1.1);
}

// ICON BASE
.icon-base {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    border-radius: 50%;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

// DRAFT
.icon-base-draft {
    background-color: #aeafaf;
}

.btn-draft {
    background-color: #eff3f8;
}

// .btn-draft:hover {
//     cursor: pointer;
//     background-color: #aeafaf50
// }

// .btn-draft:active{
//     background-color: #eff3f8;
// }

// OPEN
.icon-base-open {
    background-color: #ff7700;
}

.btn-open {
    background-color: #ffe0c5;
}

// .btn-open:hover {
//     cursor: pointer;
//     background-color: #ff770056
// }

// .btn-open:active{
//     background-color: #ff77003a;
// }

// VOID
.icon-base-void {
    background-color: #fa7070;
}

.btn-void {
    background-color: #fa707036;
}

// .btn-void:hover {
//     cursor: pointer;
//     background-color: #fa707056;
// }

// .btn-void:active{
//     background-color: #fa707036;
// }

// TRANSFER
.icon-base-transfer {
    background-color: #7aa2e3;
}

.btn-transfer {
    background-color: #e0e9f9;
}

// .btn-transfer:hover {
//     cursor: pointer;
//     background-color: #7aa2e35b;
// }

// .btn-transfer:active{
//     background-color: #7aa2e33b;
// }

// PENDING
.icon-base-pending {
    background-color: #90d26d;
}

.btn-pending {
    background: linear-gradient(90deg, #d2edc4 40%, #eff3f8 40%);
}

// .btn-pending:hover {
//     cursor: pointer;
//     background-color: #90d26d56;
// }

// .btn-pending:active{
//     background: linear-gradient( 90deg,  #90d26d67 40%,#eff3f8 40%)
// }

// PENDING KIRIM
.icon-base-pending-kirim {
    background-color: #90d26d;
}

.btn-pending-kirim {
    background: linear-gradient(90deg, #d2edc4 50%, #eff3f8 40%);
}

// .btn-pending-kirim:hover {
//     cursor: pointer;
//     background-color: #90d26d56;
// }

// .btn-pending-kirim:active{
//     background: linear-gradient( 90deg,  #90d26d67 50%,#eff3f8 40%)
// }

// PENDING TERIMA
.icon-base-pending-terima {
    background-color: #90d26d;
}

.btn-pending-terima {
    background: linear-gradient(90deg, #d2edc4 75%, #eff3f8 25%);
}

// .btn-pending-terima:hover {
//     cursor: pointer;
//     background-color: #90d26d56;
// }

// .btn-pending-terima:active{
//     background: linear-gradient( 90deg,  #90d26d67 50%,#eff3f8 40%)
// }

// PENDING CLOSE
.icon-base-close {
    background-color: #90d26d;
}

.btn-close {
    background: #d2edc4;
}

.btn-status {
    height: 25px;
    border-radius: 40px;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
}

.icon-status-base {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    border-radius: 50%;
    // top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-maps-ujt {
    height: 30px;
    border-radius: 50px;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
}

.icon-bases {
    // width: 40px;
    height: 40px;
    border-radius: 5%;
    position: absolute;
    border-radius: 5%;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-maps-ujt:hover {
    background-color: #fefffe;
    cursor: pointer;
}

.btn-maps-ujt:hover .icon-bases {
    background-color: #fefffe;
    cursor: pointer;
}

// .btn-maps-ujt:hover .icon-base {
//     transform: scale(1.1);
// }

.button-sjmap {
    background-color: #fefffe !important;
    cursor: pointer;
}

.button-sjmap:hover {
    background-color: #e8eced !important;
    cursor: pointer;
}

.button-sjmap span {
    color: red;
}

.button-gps:active {
    background-color: transparent !important;
}

.button-gps:hover {
    background-color: transparent !important;
}

.icon-base-maps {
    background-color: #ffffff;
    // border: 1px solid rgb(226, 60, 60);
}

.btn-maps {
    background-color: #ffffff;
    // border: 1px solid rgba(255, 0, 0, 0.445);
}

.btn-maps:hover {
    cursor: pointer;
}

.attendance-preview {
    width: 30%;
    @media screen and (max-width: 769px) {
        width: 80%;
    }
}

.border-container {
    border: 2px solid #ffffff;
    width: 7rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20%;
}

.image-container-attendance {
    width: 7rem;
    height: 7rem;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container-attendance img {
    width: 100%;
    padding: 0px;
    margin: 0px;
    height: auto;
    max-height: 100%;
    object-fit: cover;
}

.image-container-attendance-child {
    width: 5rem;
    height: 5rem;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container-attendance-child img {
    width: 100%;
    padding: 0px;
    margin: 0px;
    height: auto;
    max-height: 100%;
    object-fit: cover;
}

.image-container-attendance-child-list {
    width: 100%;
    height: 5rem;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container-attendance-child-list img {
    width: 100%;
    padding: 0px;
    margin: 0px;
    height: auto;
    max-height: 100%;
    object-fit: cover;
}

.rounded-image {
    border-radius: 20%;
    padding: 0px;
    margin: 0px;
}

.rounded-image-child {
    border-radius: 50%;
    padding: 0px;
    margin: 0px;
}

.camera-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}
.webcam {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    overflow: hidden;
}
.camera-container button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.addfilebutton .p-button-icon {
    font-size: 1.6rem;
    margin-left: 0.4rem;
}

.showimage-button {
    border-color: transparent !important;
    font-size: 1.5rem;
}

.dashboard-container {
    height: 60vh;
    margin-top: 4vh;
}

.absenuniteditor-table {
    height: 63.5vh;
    @media screen and (max-width: 1099px) and (max-height: 687px) {
        height: 56.5vh;
    }
}

.notablehead {
    visibility: hidden !important;
    height: 0px !important;
    position: absolute;
}

.filter-gantungan {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    @media screen and (max-width: 960px) {
        display: none;
    }
}

.menubar-gantungan {
    display: flex;
    justify-content: space-between;
    @media screen and (min-width: 960px) {
        overflow-x: auto;
    }
}

.menubar-gantungan {
    padding: 0 !important;
}

.filter-gantungan-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    // width: 100vw;
    @media screen and (max-width: 960px) {
        display: flex;
        width: 100%;
        position: absolute;
        z-index: 8;
        background-color: rgba(248, 249, 250, 255);
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        padding: 2rem;
        padding-bottom: 1.2rem;
        margin-left: 0;
    }

    @media screen and (max-width: 690px) {
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 100%;
    }
}

.p-menubar-start:has(div.filter-gantungan) {
    @media screen and (min-width: 960px) {
        overflow-x: auto;
        width: 100%;
    }
}

.button-filter-default {
    color: rgba(90, 91, 106, 255);
    background-color: rgba(252, 252, 253, 255);
    border-color: rgba(228, 231, 236, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 690px) {
        width: 100%;
    }
}

.button-filter-default span {
    font-weight: 600 !important;
}

.button-filter-chosen {
    color: rgba(99, 102, 243, 255);
    background-color: rgba(244, 243, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 690px) {
        width: 100%;
    }
}

.button-filter-chosen span {
    font-weight: 600 !important;
}

.button-filter-default:hover {
    background-color: rgba(252, 252, 253, 255) !important;
    color: rgba(90, 91, 106, 255) !important;
}

.button-filter-chosen:hover {
    color: rgba(99, 102, 243, 255) !important;
    background-color: rgba(244, 243, 255, 255) !important;
}

.tabview-nopanel .p-tabview-panels {
    display: none !important;
}

.react-pdf__Page__textContent {
    display: none;
}

.react-pdf__Page__annotations {
    display: none;
}

.p-tabview-panels:has(div.accordion-lesspadding) {
    padding: 0;
    padding-top: 1rem;
}

.map-button-container {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1000;
}

.menubar-nomobilemenu a {
    display: none !important;
}

.border-container2 {
    border: 2px solid #ffffff;
    width: 20rem;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20%;
}

.image-container-attendance2 {
    width: 20rem;
    height: 20rem;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container-attendance2 img {
    width: 100%;
    padding: 0px;
    margin: 0px;
    height: auto;
    max-height: 100%;
    object-fit: cover;
}

.gantungan-apply-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #90d26d !important;
    color: white;
    border-color: transparent !important;
    @media screen and (max-width: 690px) {
        width: 100%;
        margin-top: 1rem;
    }
    // @media screen and (max-width: 960px){
    //     width: 50%;
    // }
}

.regular-button {
    display: flex;
    justify-content: center;
    align-items: center;

    border-color: transparent !important;
    @media screen and (max-width: 690px) {
        width: 100%;
        margin-top: 1rem;
    }
    // @media screen and (max-width: 960px){
    //     width: 50%;
    // }
}

.apply-button-wrapper-mobile {
    display: flex;
    justify-content: center;
    margin-top: -0.5rem;
    @media screen and (max-width: 690px) {
        width: 100%;
    }
}

.autorefresh-checkbox-mobile {
    @media screen and (max-width: 690px) {
        width: 100%;
        margin-top: -1rem;
        margin-bottom: 0.5rem;
    }
}

.datatableheader-center .p-column-header-content {
    display: flex;
    justify-content: center;
}

.tableheader-lesspadding .p-datatable-header {
    padding: 0.3rem !important;
}

.p-tooltip-text:has(div.tooltip-nobackground) {
    background: none;
    padding: 0;
    box-shadow: none;
}

.tooltip-nobackground {
    padding: 0;
}

.p-tooltip .p-tooltip-text {
    box-shadow: none;
}

.p-tooltip-arrow {
    border-top-color: transparent !important;
}

.attendance-img-wrapper {
    width: 5vw;
}

.attendance-img {
    width: 100%;
    height: 12vh;
}

.attendance-img-wrapper-mobile {
    width: 25vw;
}

.attendance-img-mobile {
    width: 100%;
    height: 12%;
}

.text-center .p-column-header-content {
    display: flex;
    width: 100%;
    justify-content: center;
}

td:has(div.attendance-calendar-holiday) {
    background-color: #ffe6e3;
    border-bottom-width: 0px !important;
    border-top-width: 0px !important;
}

td:has(div.no-border-table) {
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    // background-color: #ffe6e3;
    // border-bottom-width: 0px !important;
    // border-top-width: 0px !important;
}

td:has(div.employeename-highlight) {
    background: rgb(194, 237, 250) !important;
}

.dnd-table .p-datatable {
    width: 100%;
    padding-right: 2rem;
}

.attendance-info-modal {
    width: 85vw;
    @media only screen and (min-width: 500px) {
        width: 65vw;
    }
}

.dialog-nopadding .p-dialog-content {
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
}

.dialog-nopadding .p-dialog-header {
    display: none;
}

.calendar-legend-button:focus {
    border-color: transparent;
    box-shadow: none !important;
}

.tablecolumns-noclickeffect {
    background: var(--surface-overlay) !important;
    color: var(--text-color) !important;
    cursor: auto;
}

.tablecolumns-disabled {
    background: lightgray !important;
    color: var(--text-color) !important;
    cursor: auto;
}

.tablecolumns-selectable:hover {
    background: rgb(232, 242, 245) !important;
}

.table-highlight-custom.p-highlight {
    background: rgb(194, 237, 250) !important;
}

.row-highlight-red {
    background: rgb(250, 194, 194) !important;
}
.row-highlight-soft-red {
    background: rgb(252, 230, 230) !important;
}

.customized-timeline .p-timeline-event-opposite {
    display: none !important;
}
.card-borderless .p-card-body {
    padding: 0 !important;
}

.card-borderless .p-card-title {
    font-size: 1.3rem;
}

td:has(div.rowgroup-header-attendance) {
    background-color: #8da3bf;
}

.filter-attendance {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    @media screen and (max-width: 1098px) {
        display: none;
    }
}

.filter-attendance-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    @media screen and (max-width: 1098px) {
        display: flex;
        width: 100%;
        position: absolute;
        z-index: 8;
        background-color: rgba(248, 249, 250, 255);
        justify-content: center;
        align-items: center;
        margin-top: 4.5rem;
        padding: 2rem;
        padding-bottom: 1.2rem;
        margin-left: 0;
    }

    @media screen and (max-width: 690px) {
        margin-top: 3.5rem;
    }

    @media screen and (max-width: 690px) {
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 100%;
    }
}

.z-7 {
    z-index: 7;
}

.z-6 {
    z-index: 6;
}

.p-timeline-event-content:has(div.card-borderless) {
    display: flex;
    justify-content: center;
}

.p-timeline-event-content:has(div.timeline-justify-start) {
    display: flex;
    justify-content: start;
}
.custom-close-icon {
    background-color: red;
    color: white;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-filter > span {
    margin-top: 0.2rem;
}

.button-filter:focus {
    box-shadow: none !important;
}

// .custom-margin-timeline{

//         margin-left: -16rem;

//     @media screen and (max-width: 1500px){
//         margin-left: -12rem;
//     }

//     @media screen and (max-width: 1300px){
//         margin-left: -8rem;
//     }

//     @media screen and (max-width: 1200px){
//         margin-left: -5rem;
//     }

// }

.p-timeline-horizontal .p-timeline-event {
    flex: 1 !important;
}

.swiper-wrapper {
    height: 15vh !important;
}

.p-organizationchart-table {
    min-width: 12rem;
    // min-width: 7.5vw;
    // @media screen and (max-width: 1098px){
    //     min-width: 12.5vw;
    // }

    // @media screen and (max-width: 1000px){
    //     min-width: 18vw;
    // }

    // @media screen and (max-width: 687px){
    //     min-width: 21vw;
    // }

    // @media screen and (max-width: 500px){
    //     min-width: 31vw;
    // }
}

.p-organizationchart-line-right {
    min-width: 0.3rem !important;

    // @media screen and (max-width: 1098px){
    //     min-width: 0.3rem !important;
    // }
}

// .occupation-tree-container::-webkit-scrollbar-thumb{
//     scrollbar-width: thin;
// }

.occupation-tree-container::-webkit-scrollbar:horizontal {
    height: 6px;
}

.occupation-tree-container::-webkit-scrollbar-track:horizontal {
    background: #f1f1f1;
}

.occupation-tree-container::-webkit-scrollbar-thumb:horizontal {
    border-radius: 6px;
    background: #888;
}

.occupation-tree-container::-webkit-scrollbar-thumb:hover:horizontal {
    background-color: #555;
}

.custom-table-row-height-new {
    padding-top: 0.2% !important;
    padding-bottom: 0.2% !important;
}

.custom-table-row-height-invoice {
    height: 20% !important;
}

.custom-table-row-height-new-max {
    padding-top: 0.01% !important;
    padding-bottom: 0.01% !important;
}

.custom-table-row-height {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.custom-table-row-height-2 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.custom-table-row-height-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}
.custom-table-row-height-4p5 {
    padding-top: 4.5px !important;
    padding-bottom: 4.5px !important;
}

.custom-table-row-height-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.custom-table-row-height-7 {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.custom-table-row-height-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.custom-table-row-height-9 {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.custom-table-row-height-11 {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
}

.custom-table-row-height-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.custom-table-row-height-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
}

.custom-table-row-height-14 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

.custom-table-row-height-14p5 {
    padding-top: 14.5px !important;
    padding-bottom: 14.5px !important;
}

.custom-table-row-height-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.custom-table-row-height-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

.custom-table-row-height-17 {
    padding-top: 17.5px !important;
    padding-bottom: 17.5px !important;
}

.custom-table-row-height-18 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
}

.custom-table-row-height-19 {
    padding-top: 19px !important;
    padding-bottom: 19px !important;
}

.custom-table-row-height-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.custom-table-row-height-1p6rem {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important;
}

.text-overflow-datatble {
    white-space: nowrap;
    overflow: hidden;
    // position: relative;
}

.text-overflow-datatble:has(:not(.donotuseEllipsis)) {
    text-overflow: ellipsis;
    max-width: 120px;
    // position: relative;
}

// .tooltip-container {
//     position: relative;
//     display: inline-block;
//     z-index: 1000;
// }

// .tooltip-text {
//     visibility: hidden;
//     background-color: black;
//     color: #ffffff;
//     font-weight: bold;
//     text-align: center;
//     border-radius: 5px;
//     padding: 5px;
//     position: absolute;
//     z-index: 1001;
//     bottom: 125%;
//     left: 50%;
//     transform: translateX(-50%);
//     opacity: 0;
//     transition: opacity 0.3s;
//     white-space: nowrap;
//     width: auto;
//     max-width: 300px;
//     box-sizing: border-box;
// }

// .tooltip-container:hover .tooltip-text {
//     visibility: visible;
//     opacity: 1;
//     z-index: 1002;
// }
// .tooltips {
//     box-shadow: none !important;
//     // background: #fff !important;
//     // color: #000 !important;
//     border: 1px solid #ddd !important;
// }

.button-borderless {
    border-color: transparent !important;
}

.button-borderless:focus {
    box-shadow: none;
}

.button-fileupload-add {
    background-color: #c7eeea !important;
    color: #14b8a6 !important;
}

.button-fileupload-add:hover {
    // background-color: #e1e1fc !important;
    background-color: #9fe3dc !important;
    color: #14b8a6 !important;
}

.button-fileupload-clear {
    // background-color: #f5f5ff !important;
    background-color: #ffd0ce !important;
    color: #ff3d32 !important;
}

.button-fileupload-clear:hover {
    background-color: #f7a7a3 !important;
    color: #ff3d32 !important;
}

.button-fileupload-remove {
    // background-color: #f5f5ff !important;
    background-color: #f7eceb !important;
    color: #ef4444 !important;
}

.button-fileupload-remove:hover {
    background-color: #e6d9d8 !important;
    color: #ef4444 !important;
}

.button-fileupload-expand {
    // background-color: #f5f5ff !important;
    background-color: #ebecf7 !important;
    color: var(--primary-color) !important;
}

.button-fileupload-expand:hover {
    background-color: #dcdde3 !important;
    color: var(--primary-color) !important;
}

.button-dialog-close {
    // background-color: #f5f5ff !important;
    background-color: white !important;
    color: gray !important;
}

.button-dialog-close:hover {
    background-color: white !important;
    color: gray !important;
}

.button-nooutline:focus {
    box-shadow: none !important;
}

.button-text-center > span {
    margin-top: 0.12rem;
}

.p-autocomplete-multiple-container {
    width: 100% !important;
}

.p-datepicker-inline {
    width: 100% !important;
}

.circle-container {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.half-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    top: 0;
    transform: rotate(45deg); /* Rotasi sebesar 45 derajat */
}

.rit {
    background-color: rgb(248, 194, 194);
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);
}

.target_rit {
    background-color: rgb(185, 250, 185);
    clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
}

.circle-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    z-index: 1;
}

.rit-text {
    position: absolute;
    left: 15px; /* Sesuaikan jarak dari kiri */
    top: 50%;
    transform: translateY(-50%);
}

.target-text {
    position: absolute;
    right: 15px; /* Sesuaikan jarak dari kanan */
    top: 50%;
    transform: translateY(-50%);
}

.horizontal-scroll-container-target {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0.5rem;
}

.item-container-target {
    display: inline-block;
    vertical-align: top;
    width: 400px;
    margin-right: 1rem;
}

@keyframes moveShadowSuccess {
    0% {
        box-shadow: 0 0 6px rgb(106, 211, 106);
    }
    25% {
        box-shadow: 6px 0 6px rgb(106, 211, 106);
    }
    50% {
        box-shadow: 6px 6px 6px rgb(106, 211, 106);
    }
    75% {
        box-shadow: 0 6px 6px rgb(106, 211, 106);
    }
    100% {
        box-shadow: 0 0 6px rgb(106, 211, 106);
    }
}

.div-target-success {
    animation: moveShadowSuccess 3s linear infinite;
    box-shadow: 0 0 6px rgb(106, 211, 106);
    border-color: #d9dbda;
    border-style: solid;
    border-width: 1px;
}

@keyframes moveShadowInfo {
    0% {
        box-shadow: 0 0 6px rgb(82, 209, 248);
    }
    25% {
        box-shadow: 6px 0 6px rgb(82, 209, 248);
    }
    50% {
        box-shadow: 6px 6px 6px rgb(82, 209, 248);
    }
    75% {
        box-shadow: 0 6px 6px rgb(82, 209, 248);
    }
    100% {
        box-shadow: 0 0 6px rgb(82, 209, 248);
    }
}

.div-target-info {
    animation: moveShadowInfo 3s linear infinite;
    box-shadow: 0 0 6px rgb(82, 209, 248);
    border-color: #d9dbda;
    border-style: solid;
    border-width: 1px;
}

@keyframes moveShadowError {
    0% {
        box-shadow: 0 0 6px rgb(240, 120, 120);
    }
    25% {
        box-shadow: 6px 0 6px rgb(240, 120, 120);
    }
    50% {
        box-shadow: 6px 6px 6px rgb(240, 120, 120);
    }
    75% {
        box-shadow: 0 6px 6px rgb(240, 120, 120);
    }
    100% {
        box-shadow: 0 0 6px rgb(240, 120, 120);
    }
}

.div-target-error {
    animation: moveShadowError 3s linear infinite;
    box-shadow: 0 0 6px rgb(240, 120, 120);
    border-color: #d9dbda;
    border-style: solid;
    border-width: 1px;
}

$DnDTableBorderColor: #c9c4c3;

.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
    border: 1px dashed $DnDTableBorderColor;
}

/* Apply a border to the right of all but the last column */
table.rounded-corners th:not(:last-child),
table.rounded-corners td:not(:last-child) {
    border-right: 1px dashed $DnDTableBorderColor;
}

/* Apply a border to the bottom of all but the last row */
table.rounded-corners > thead > tr:not(:last-child) > th,
table.rounded-corners > thead > tr:not(:last-child) > td,
table.rounded-corners > tr:not(:last-child) > th,
table.rounded-corners > tr:not(:last-child) > td,
table.rounded-corners > tr:not(:last-child) > th,
table.rounded-corners > tfoot > tr:not(:last-child) > td,
table.rounded-corners > tr:not(:last-child) > td,
table.rounded-corners > tr:not(:last-child) > th,
table.rounded-corners > thead:not(:last-child),
table.rounded-corners > tbody:not(:last-child),
table.rounded-corners > tfoot:not(:last-child) {
    border-bottom: 1px dashed $DnDTableBorderColor;
}

.flex-container-dashboard {
    display: flex;
    flex-wrap: wrap;
}

.flex-item-dashboard {
    flex: 0 0 100%;
    box-sizing: border-box;
}

@media (min-width: 996px) {
    .flex-item-dashboard {
        flex: 0 0 calc(30%);
    }
}

@media (min-width: 1200px) {
    .flex-item-dashboard {
        flex: 0 0 calc(20%);
    }
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
}

.shadow-4-stnk {
    padding: 20px;
    position: relative;
    border-radius: 8px;
    border: 2px solid rgba(224, 218, 224, 0.8);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 15px rgba(0, 0, 0, 0.2);
    z-index: 0;
}

.shadow-4-plate {
    padding: 20px;
    position: relative;
    border-radius: 8px;
    border: 2px solid rgba(224, 218, 224, 0.8);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 15px rgba(0, 0, 0, 0.2);
    z-index: 0;
}
.shadow-4-barcode {
    padding: 20px;
    position: relative;
    border-radius: 8px;
    border: 2px solid rgba(224, 218, 224, 0.8);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 15px rgba(0, 0, 0, 0.2);
    z-index: 0;
}
.shadow-4-tid {
    // background: linear-gradient(
    //     to right,
    //     rgba(255, 8, 0, 0.8),
    //     rgba(0, 128, 255, 0) 25%
    // );
    padding: 20px;
    position: relative;
    border-radius: 8px;
    // border: 2px solid rgba(255, 8, 0, 0.8);
    border: 2px solid rgba(224, 218, 224, 0.8);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 15px rgba(0, 0, 0, 0.2);
    z-index: 0;
}
.shadow-4-kir {
    // background: linear-gradient(
    //     to right,
    //     rgba(255, 0, 255, 0.8),
    //     rgba(0, 128, 255, 0) 25%
    // );
    padding: 20px;
    position: relative;
    border-radius: 8px;
    // border: 2px solid rgba(255, 0, 255, 0.8);
    border: 2px solid rgba(224, 218, 224, 0.8);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 15px rgba(0, 0, 0, 0.2);
    z-index: 0;
}

.dumptruck-color {
    filter: invert(35%) sepia(70%) saturate(600%) hue-rotate(75deg) brightness(110%) contrast(100%);
}

.cargo-color {
    filter: invert(50%) sepia(100%) saturate(600%) hue-rotate(-25deg) brightness(105%) contrast(100%);
}

.mixer-color {
    filter: invert(29%) sepia(98%) saturate(7481%) hue-rotate(-15deg) brightness(102%) contrast(105%);
}

.citycar-color {
    filter: invert(43%) sepia(93%) saturate(7249%) hue-rotate(182deg) brightness(101%) contrast(102%);
}

.time-red-label,
.time-green-label {
    padding: 5px 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 3px;
    font-size: 1em;
    position: absolute;
    top: 5px;
    left: -30px;
    max-width: 100px;
    text-align: center;
    line-height: 1.2;
    white-space: normal;
    word-wrap: break-word;
}

.time-red-label {
    background-color: red;
    color: white;
}

.time-green-label {
    border: 0.5px solid;
    border-color: #d4cbcb;
    background-color: rgb(255, 255, 255);
    color: white;
}

.ribbon {
    text-align: center;
    position: relative;
    color: #fff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.8);
    background-color: "red";
}

.ribbon::before {
    content: "";
    position: absolute;
    border-style: solid;
    border-color: transparent;
    bottom: -10px;
    border-width: 0 10px 10px 0;
    border-right-color: #800707;
    left: 0;
}

// .bod-custom-scrollbar {
//     scrollbar-width: thin;
//     scrollbar-color: #888 transparent;
// }

//  .bod-custom-scrollbar::-webkit-scrollbar {
//     height: 6px;
// }

// .bod-custom-scrollbar::-webkit-scrollbar-thumb {
//     background-color: #888;
//     border-radius: 3px;
// }

// .bod-custom-scrollbar::-webkit-scrollbar-track {
//     background: transparent;
// }

.bod-custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}

.bod-custom-scrollbar::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.bod-custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.bod-custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

// .layout-main-container{
//     overflow: hidden;
// }

.custom-autocomplete-panel {
    height: 20px;
    overflow-y: auto;
}

.panel-py-0 .p-panel-content {
    padding-top: 0.5rem !important;
    padding-bottom: 0 !important;
}

table.table-noheader .p-datatable-thead {
    display: none;
}

.accordiontab-nopadding .p-accordion-content {
    padding: 0 !important;
}

.table-small {
    height: 1rem !important;
}

.p-accordion-tab:has(.accordion-mb2) {
    /* Your styles here */
    margin-bottom: 0.5rem; /* Example style */
}

.accordiontab-nopadding > a {
    height: 3.5rem;
}

.calendar-noheader .p-datepicker-group-container {
    display: none;
}

.calendar-filter-year .p-yearpicker-year {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
}

.sjImage {
    // object-fit: cover;
    //  min-width: 30%;
    // max-width: 250px;
    height: 100%;
    border-radius: 20;
}

.sjImage .p-image img {
    // object-fit: cover;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 20;
}

.frozen-right {
    position: sticky;
    right: 0px;
}

.frozen-left {
    position: sticky;
    left: 0px;
}

.p-rowgroup-header:has(tr.orderdaily-rowgroup) {
    z-index: 4;
}

.expiredpwfield .p-password-input {
    width: 100%;
}

.expiredpwfield .p-icon-field {
    width: 100%;
}

.expiredpwfield > svg {
    margin-top: -0.6rem !important;
}

////Post update manual fix

.p-highlight > .p-radiobutton-box {
    background: #6366f1 !important;
}

.p-highlight > .p-inputswitch-slider {
    background: #6366f1 !important;
}

.p-highlight > .p-checkbox-box {
    background: #6366f1 !important;
}

.fieldset-lesspadding .p-fieldset-content {
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
}

.button-additem .p-button-label {
    padding-top: 0.5rem !important ;
}

.approve-button-menubar .p-menuitem-icon {
    color: #22c55e !important;
}

.approve-button-menubar .p-menuitem-text {
    color: #22c55e !important;
}

.reject-button-menubar .p-menuitem-icon {
    color: red !important;
}

.reject-button-menubar .p-menuitem-text {
    color: red !important;
}

.pulse-container {
    display: inline-flex;
    position: relative;
    margin-top: 0.7rem;
    animation: pulseicon 1.5s infinite;
}

.pulse-container::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(233, 91, 90, 0.7);
    transform: translate(-50%, -50%) scale(1);
    animation: pulse 1.5s infinite;
    z-index: -1; /* Place it behind the SVG */
}

.approval-progress-node {
    margin-top: 1.5rem;
    margin-left: 1.7rem;
}

.pulse-container-approval {
    display: inline-flex;
    position: relative;

    animation: pulseiconapproval 1.5s infinite;
}

.pulse-container-approval::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(#5adb88, 0.7);
    // background-color: rgba(#e6a547, 0.7);
    transform: translate(-50%, -50%) scale(1);
    animation: pulse 1.5s infinite;
    z-index: -1; /* Place it behind the SVG */
}

@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
    }
}

@keyframes pulseiconapproval {
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pulseicon {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }
    70% {
        transform: translate(-50%, -50%) scale(1.3);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

.hold-frozen-fix {
    right: 96px !important;
}

.p-inputnumber-button {
    padding: 0.25rem 0 !important;
}

.inputnumber-with-button > .p-inputnumber-input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.dialog-width {
    @media (max-width: 768px) {
        width: 70vw;
    }

    @media (min-width: 992px) {
        width: 40vw;
    }

    @media (min-width: 1200px) {
        width: 25vw;
    }
}

.input-jadwal .p-panel-content {
    padding-bottom: 2rem;
}

.sj-kembali .p-panel-content {
    padding-bottom: 1.3rem;
}

.quantity-panel .p-panel-content {
    padding-bottom: 2.2rem;
}
