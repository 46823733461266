.slip-page {
    width: 210mm;
    height: 139mm;
    padding: 5mm;
    margin: 5mm auto;
    border: 1px #333 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    page-break-after: always;
    page-break-inside: avoid;
    clear: both;
    font-size: "14px";
}

.slip-page-break {
    page-break-before: always;
}

.slip-page .slip-table {
    width: 100%;
}

.slip-page .slip-table thead tr th {
    border: none;
}

.slip-page .slip-table .slip-table-info {
    width: 100%;
    border-collapse: collapse;
    // border: 1px solid black;
}

.slip-page .slip-table .slip-table-info tr td {
    border: none;
    text-align: left;
    width: auto !important;
    max-width: 100%;
    white-space: nowrap;
}

.slip-page .slip-table-info .slip-table-info-title {
    font-weight: bold;
}

.slip-company {
    text-align: right;
}

.slip-title {
    text-align: right;
    font-weight: bold;
    font-size: 14px;
}

.slip-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.slip-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.slip-page .slip-table-detail {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid #dee2e6;
}

.slip-page .slip-table-detail td:first-child {
    text-align: left;
}

.slip-page .slip-table-detail thead tr th {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    text-align: center;
}

.slip-page .slip-table-detail th,
.slip-table-detail td {
    padding-top: 3px;
    text-align: right;
}

.slip-page .slip-table .slip-table-delivery-info {
    width: 100%;
    border-collapse: collapse;
}

.slip-page .slip-table .slip-table-delivery-info tr td {
    border: none;
    text-align: left;
    width: auto !important;
    max-width: 100%;
    padding-bottom: 5px;
    font-size: 13px;
    overflow-wrap: break-word;
}

.slip-page .slip-table-delivery-info .slip-table-delivery-info-title {
    font-weight: bold;
}

.slip-page .slip-table-delivery {
    width: 100%;
    border-collapse: collapse;
    border: dotted 1px #000;
    height: 160px;
}

.slip-page .slip-table-delivery td:first-child {
    text-align: left;
}

.slip-page .slip-table-delivery thead tr th {
    padding: 8px;
    border: dotted 1px #000;
    text-align: center;
    font-size: 14px;
}

.slip-page .slip-table-delivery td {
    padding: 20px;
    border: dotted 1px #000;
    font-size: 16px;
}

.slip-page .slip-table-delivery-sign {
    margin-top: -1px;
    border-collapse: collapse;
    margin-right: 0;
    margin-left: auto;
    width: 100%;
    height: 150px;
}

.slip-page .slip-table-delivery-sign thead tr th {
    padding: 10px;
    text-align: center;
    font-size: 14px;
}

.slip-page .slip-table-delivery-sign .penerima {
    width: 150px;
    height: 150px;
    // border: dotted 1px #000;
}

.slip-border-top {
    border-top: 1px solid #dee2e6;
}

.slip-border-bottom {
    border-bottom: 1px solid #dee2e6;
}

.slip-border-topBottom {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.slip-text-right {
    text-align: right;
}

.slip-border {
    border-top: dotted 1px #000;
    margin-bottom: 10px;
}

@media print {
    body {
        margin: 0;
        padding: 0;
    }

    .slip-page {
        margin: 0;
        margin-bottom: 0;
        width: 210mm;
        height: 130mm;
        border: initial;
        border-radius: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        page-break-inside: avoid;
        -webkit-border-radius: initial;
        -moz-border-radius: initial;
        -ms-border-radius: initial;
        -o-border-radius: initial;
    }

    .print-finances {
        margin: 0;
        // margin-bottom: 0;
        margin-right: 1000;
        width: 210mm;
        height: 297mm;
        border: initial;
        border-radius: initial;
        box-shadow: initial;
        background: initial;
        // page-break-after: always;
        // page-break-inside: avoid;
        -webkit-border-radius: initial;
        -moz-border-radius: initial;
        -ms-border-radius: initial;
        -o-border-radius: initial;
    }

    .printContent {
        margin: 0;
        width: 8.5in;
        height: 11in;
        border: initial;
        border-radius: initial;
        box-shadow: initial;
        background: initial;
        page-break-inside: auto;
        -webkit-border-radius: initial;
        -moz-border-radius: initial;
        -ms-border-radius: initial;
        -o-border-radius: initial;
    }

    // .dataInvoice {
    //     height: auto;
    // }

    // .invoiceHead {
    //     height: 2in;
    //     width: 100%;
    //     position: fixed;
    //     flex: auto;
    //     justify-content: space-between;
    // }

    .printContent {
        margin-bottom: 0;
    }

    .invoiceBody {
        height: 4.3in;
    }

    .invoiceFoot {
        min-height: 2.6in;
    }

    .footerCalculate {
        position: absolute;
        bottom: 0;
        width: 98%;
        display: flex;
        // justify-content: flex-start;
        // align-items: flex-end;
        // padding-top: 50px;
        z-index: 998;
    }

    .tr-space {
        padding-top: 0;
    }
}

.slip-cashorder {
    width: 7.7in;
    height: 5.3in;
    margin: 0;
    border-radius: 5px;
    background: white;
    //  border: 1px #333 solid;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    page-break-after: always;
    page-break-inside: avoid;
    clear: both;
    font-size: "14px";
}
.cashslip-border-top {
    width: 100%;
    border-collapse: collapse;
    border-top: dashed 0.5px #000;
    border-bottom: dashed 0.5px #000;
    height: 0.8rem;
}

.invoice-table-head {
    border-top: 2.5px solid black;
    border-bottom: 2.5px solid black;
}

.cashslip-border-bottom {
    width: 100%;
    border-collapse: collapse;
    border: solid 0.6px #000;
}

.invoice-bottom-container {
    display: flex;
    flex-direction: row;
    border-top: 2.5px solid black;
}

.invoice-bottom-totals {
    border-left: 2.5px solid black;
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
}

.payslip-bottom-potongan {
    border-left: 2.5px solid black;
}

.invoice-bottom-payment {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.invoice-border-bottom {
    border: 1.25px solid black;
}

.underline-border {
    border-bottom: 1.25px solid black;
}
